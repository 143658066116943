.goods {
  animation: fadein 1s ease;
}

@keyframes fadein {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}